import React from "react"

const notFound = () => {
  return (
    <section className="page-404">
      <div className="container">
        <h1>Page not found</h1>
        <a href="/" className="btn">
          Go back home
        </a>
      </div>
    </section>
  )
}

export default notFound
